.logo {
	width: 200px;
	position: absolute;
	top: 50%;
	left: 50%;
	margin-left: -100px;
	margin-top: -177px;

	img {
		width: 100%;
	}

	@include susy-breakpoint($small) {
		width: 300px;
		position: absolute;
		top: 50%;
		left: 50%;
		margin-left: -150px;
		margin-top: -160px;
		padding-bottom: 100px;
	}

}

// .line {
// 	height: 1px;
// 	background: black;
// 	position: absolute;
// 	width: 100%;
// 	top: 50%;
// }
