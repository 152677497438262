body {
	animation: colorchange 20s; /* animation-name followed by duration in seconds*/
	/* you could also use milliseconds (ms) or something like 2.5s */
	-webkit-animation: colorchange 20s; /* Chrome and Safari */
}

@keyframes colorchange
{
	0%   {background: #efc1e7;}
	25%  {background: #81E8F4;}
	75%  {background: #81E8F4;}
	100% {background: #efc1e7;}
}
